import { template as template_03aeaa7f6efe4bbdbdb7f0d0cbed31db } from "@ember/template-compiler";
const FKControlMenuContainer = template_03aeaa7f6efe4bbdbdb7f0d0cbed31db(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
