import { template as template_11eaa6702acb4ffb8ec136307c0669e2 } from "@ember/template-compiler";
const FKText = template_11eaa6702acb4ffb8ec136307c0669e2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
