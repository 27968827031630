import { template as template_a4cce2fb2937471ba02fa5eceb4c25e0 } from "@ember/template-compiler";
const FKLabel = template_a4cce2fb2937471ba02fa5eceb4c25e0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
