import { template as template_9877120e25e043469e5351d6b435f295 } from "@ember/template-compiler";
const WelcomeHeader = template_9877120e25e043469e5351d6b435f295(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
